<template>
    <div role="products" aria-labelledby="caption" tabindex="0" :style="styleCont">
        <table v-if="!loading">
            <thead>
                <tr>
                    <th
                        v-for="(field, index) in fields"
                        :key="`heading-${field.id ? field.id + index : index}`"
                        scope="col"
                        class="cursor-pointer"
                        @click="sort(field.key)"
                    >
                        {{ field.label }}
                        <span
                            class="mdi mdi-menu-down text-tiny tracking-tighter leading-nonew"
                            :class="{ 'text-catBlue': currentSort === field.key && currentSortDir === 'desc' }"
                        ></span>
                        <span
                            class="mdi mdi-menu-up text-tiny tracking-tighter leading-nonew"
                            :class="{ 'text-catBlue': currentSort === field.key && currentSortDir === 'asc' }"
                        ></span>
                    </th>
                </tr>
            </thead>

            <tbody>
                <template v-if="sortedItems.length">
                    <tr v-for="item in sortedItems" :key="item.id + Math.random() * 100">
                        <td
                            v-for="(field, index) in fields"
                            :key="`col-${field.id ? field.id + index : index}`"
                            class="px-0 py-4 whitespace-nowrap"
                            :style="{ minWidth: field.width + 'px' }"
                            :class="{
                                'first-table-column': field.width == 300,
                                'bg-good-opacity-20':
                                    (item.sale_percent > 1 && field.key == 'sale_percent') ||
                                    (item.profit_growth > 1 && field.key == 'profit_growth'),
                            }"
                        >
                            <slot :name="field.key" :index="index" :item="item" :value="item[field.key]" :field="field">
                                <span v-if="field.prefix" class="relative inline-block w-auto">
                                    {{ field.prefix }}
                                </span>
                                <span
                                    v-if="field.format === 'fixed' && item[field.key] !== parseInt(item[field.key], 10)"
                                    class="relative inline-block w-auto"
                                >
                                    {{ item[field.key] | numFormat('0,0.0a') }}
                                </span>
                                <span v-else class="relative inline-block w-auto">
                                    {{ item[field.key] | numFormat('0,0') }}
                                </span>
                                <span v-if="field.postfix" class="relative inline-block">
                                    <small>{{ field.postfix }}</small>
                                </span>
                            </slot>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td
                            :colspan="fields.length"
                            class="text-center h-40"
                            style="color: rgba(121, 132, 154, var(--tw-text-opacity))"
                        >
                            SIN RESULTADOS
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <loading v-else />
    </div>
</template>

<script>
    import Loading from '@/components/elements/Loading';
    export default {
        name: 'Table',
        components: { Loading },
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            items: {
                type: [Array, Object],
                default: () => [],
            },
            fields: {
                type: [Array, Object],
                default: () => [],
            },
            styleCont: {
                type: String,
                required: false,
                default: '',
            },
        },
        data() {
            return {
                currentSort: 'sales',
                currentSortDir: 'asc',
            };
        },
        methods: {
            sort: function (s) {
                //if s == current sort, reverse
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
            },
        },
        computed: {
            sortedItems: function () {
                return [].slice.call(this.items).sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            },
        },
    };
</script>

<style>
    table {
        color: white;
        white-space: nowrap;
        margin: 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
    }
    table td,
    table th {
        /* border: 1px solid black; */
        padding: 0.5rem 1rem;
    }
    table thead th {
        padding: 3px;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 25vw;
    }
    table td {
        padding: 4px 125px;
        text-align: center;
    }

    table tbody th {
        font-weight: 100;
        text-align: left;
        position: relative;
    }
    table thead th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        @apply bg-module text-gray2 text-xs;
    }
    table thead tr th {
        @apply bg-module text-gray2 text-xs;
    }
    table th:first-child {
        /* background-color: blue; */
    }
    table tbody td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        @apply bg-module;
    }
    @media only screen and (max-width: 600px) {
        table thead th:first-child {
            position: relative;
        }
        table tbody td:first-child {
            position: relative;
        }
    }
    caption {
        text-align: left;
        padding: 0.25rem;
        position: sticky;
        left: 0;
    }

    .first-table-column {
        box-shadow: 10px 0 13px -10px #111621b5;
        border-bottom: 2px solid #1d2532 !important;
    }

    [role='products'][aria-labelledby][tabindex] {
        width: 100%;
        max-height: 75vh;
        overflow: auto;
    }
    [role='products'][aria-labelledby][tabindex]:focus {
        /* box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5); */
        outline: 0;
    }

    tr {
        @apply relative py-2 px-5 mb-3;
    }
    tr:nth-child(even) {
        @apply bg-box;
    }
    table th:first-child {
        @apply bg-module;
    }
    table .first-column:nth-child(even) {
        @apply bg-box;
    }
</style>
