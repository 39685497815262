<template>
    <div class="w-full flex flex-col" v-if="data">
            <ModuleHeader :noFilters="true">
                <template v-slot:module>
                    Almacén
                </template>
                <!-- <template v-slot:period>
                    {{ period.from | moment("DD MMM YY") }} <i class="mdi mdi-arrow-right"></i> {{ period.to | moment("DD MMM YY") }}
                </template> -->
                <!-- <template v-slot:market>
                    {{ market.name }}
                </template> -->
            </ModuleHeader>
            <Box>
                <template v-slot:header>
                    Nº Referencias
                </template>
                <template v-slot:content>
                    <div class="text-white text-xl xl:text-2xl font-medium flex flex-wrap content-center justify-center items-center my-2">
                       {{data.references | numFormat('0,0')}}
                    </div>
                </template>
            </Box>

            <Box>
                <template v-slot:header>
                    Valor compra
                </template>
                <template v-slot:content>
                    <div class="text-white text-xl xl:text-2xl font-medium flex flex-wrap content-center justify-center items-center my-2">
                       {{data.purchases_current | numFormat('0,0.0') }} €
                    </div>
                    <div class="w-full flex justify-between items-center">
                        <div class="sm-value">{{data.purchases_previous | numFormat('0,0.0')}} €</div>
                        <div class="sm-value"><span v-if="data.purchase_growth > 0">+</span>{{ data.purchase_growth | numFormat('0.00') }}%</div>
                    </div>
                </template>
            </Box>
    
            <Box>
                <template v-slot:header>
                    Valor venta
                </template>
                <template v-slot:content>
                    <div class="text-white text-xl xl:text-2xl font-medium flex flex-wrap content-center justify-center items-center my-2">
                        {{data.sales_current | numFormat('0,0.0') }} €
                    </div>
                    <div class="w-full flex justify-between items-center">
                        <div class="sm-value">{{data.sales_previous | numFormat('0,0.0')}} €</div>
                        <div class="sm-value"><span v-if="data.sale_growth > 0">+</span>{{ data.sale_growth | numFormat('0.00') }}%</div>
                    </div>
                </template>
            </Box>
    
            <Box>
                <template v-slot:header>
                    Tendencia
                </template>
                <template v-slot:content>
                    <div class="w-full h-40">
                        <AreaSpline
                            :datatype="'€'"
                            :chartId="'chartId'" 
                            :data="data.evolution"
                            :xDate="'%d %B %Y'"
                        />
                    </div>
                </template>
            </Box>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Box from '@/components/Box.vue';
    import AreaSpline from '@/components/charts/AreaSpline.vue';
    import Paginate from "@/api/components/Paginate";
    import {state} from "@/store";
    import numFormat from "vue-filter-number-format";
    
    export default {
        name: 'ProductWarehouse',
        props: {
            data: {
                type: [Array, Object],
                required: true
            },
        },
        data() {
          return {
              warehouse: {}
          }
        },
        methods: {
            // formatNumber(n){
            //   return (n | numFormat('0,0.0'))
            // },
            // showGrowth(value) {
            //     //const out = value > 0 ? '+' ? value < 0 ? '-' :  '';
            //     return (value > 0) ? '+' : ((value < 0) ? '-' : (value))
            // },
        },
        computed: {
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            }
            
        },
        components: {
            Paginate,
            ModuleHeader,
            Box,
            AreaSpline,
        }
    }
</script>