<template>
    <div class="relative chart-wrap">
        <highcharts  class="chart w-full h-full" :options="options" :ref="chartId" :id="chartId"></highcharts>
    </div>
</template>

<script>
export default {
    name: 'AreaSpline',
    props: {
        chartId: {
            type: String,
            required: false,
            default: 'chartId'
        },
        datatype: {
            type: String,
            required: false,
            default: ''
        },
        data: {
            type: [Object,Array],
            required: true,
            default: () => ({})
        },
        xDate: {
            type: String,
            required: false,
            default: '%B %Y'
        }
    },
    data() {
        return {
            options: {
                chart: {
                    
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    backgroundColor:'rgba(255,255,255,0)',
                    spacingBottom: 10,
                    spacingTop: 100,
                    spacingRight: 0,
                    spacingLeft: 0,
                    marginTop: 10,
                    marginRight: 0,
                    marginRLeft: 0,
                },
                lang: {
                    thousandsSep: '.',
                    decimalPoint: ',',
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                credits:{
                    enabled: false
                },
                xAxis: [{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        style:{
                            color:'#79849A',
                            fontWeight: 'medium',
                            fontSize: '7.5px',
                        }
                    },
                    tickPixelInterval: 75,
                    tickWidth: 0,
                },{
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b'
                    },
                    lineColor: 'rgba(255,255,255,0)',
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false,
                    },
                    tickPixelInterval: 75,
                    tickWidth: 0,
                    opposite: true
                }],
                time: {
                    timezoneOffset: new Date().getTimezoneOffset()
                },
                yAxis: {
                    title: {
                        text: '',
                    },
                    gridLineColor: '#283143',
                    lineColor: '#79849A',
                    lineWidth: 1,
                    labels: {
                        enabled: true,
                        style:{
                            color:'#79849A',
                            fontWeight: 'medium',
                            fontSize: '7.5px',
                        },
                    },

                },
                tooltip: {
                    enabled:true,
                    style: {
                        color: "#fff",
                    },
                    shared: true,
                    borderRadius: 20,
                    borderWidth: 0,
                    headerFormat: '<span style="font-size:.65rem; color: rgb(121, 132, 154);">{point.key}</span><br>',
                    xDateFormat: this.xDate,
                    pointFormat:
                    '<span class="capitalize" style="color:{point.color}">{series.name}</span>: <b>{point.y:,.1f}' + this.datatype + '</b><br/>',
                },
                plotOptions: {
                    areaspline:{
                        fillOpacity:1,
                        lineWidth: 3,
                        marker:{
                            enabled:false
                        },
                        series: {
                            animation: {
                                duration: 4000
                            },
                            shadow: true,
                        },
                    },
                    line:{
                        fillOpacity:1,
                        lineWidth: 3,
                        dashStyle: 'dash',
                        marker:{
                            enabled:false
                        },
                        series: {
                            animation: {
                                duration: 4000
                            },
                            shadow: true,
                        },

                    },
                },
                legend: {
                    enabled: false
                },
                data: {
                    enablePolling: true,
                    dataRefreshRate: 1
                },
                series: [
                    {
                        type: 'areaspline',
                        xAxis: 1,
                        name: 'Anterior',
                        data: this.data.previous,
                        lineWidth: 0,
                        color: 'rgba(89,100,117,1)',
                        zIndex: 0,
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, 'rgba(89,100,117,0.8'],
                                [1, 'rgba(89,100,117,0.1'],
                                [2, 'transparent'],
                                [3, 'transparent'],
                            ]
                        },
                    },{
                        type: 'areaspline',
                        name: 'Actual',
                        data: this.data.current,
                        lineWidth: 2,
                        zIndex: 5,
                        color: {
                            linearGradient: [500, 500, 0, 0],
                            stops: [
                                [0, '#0ACFFE'],
                                [1, '#495AFF'],
                                [2, 'transparent'],
                                [3, 'transparent'],
                            ]
                        },
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 1
                            },
                            stops: [
                                [0, '#0ACFFE'],
                                [1, 'rgba(73,90,255,0.1'],
                                [2, 'transparent'],
                                [3, 'transparent'],
                            ]
                        },
                        shadow: {
                            offsetX: 0,
                            offsetY: 4,
                            opacity: .06,
                            width: 10,
                        },
                    },{
                        type: 'line',
                        name: 'Tendencia',
                        data: this.data.trend,
                        lineWidth: 2,
                        color: 'orange',
                        zIndex: 10,
                    },
                ]
            }
        }
    },

    methods: {
        // load() {
        //     let chart = this.$refs[this.chartId].chart;
        //     var i = 0
        //     for(var serie in this.data){
        //         let getSerie = chart.get(serie);
        //         if(getSerie){
        //             chart.series[i].setData(this.data[serie])
        //         } else {
        //             chart.addSeries({
        //                 //xAxis: i,
        //                 id:serie,
        //                 name:this.dataName[i],
        //                 data:this.data[serie],
        //                 color: {
        //                     linearGradient: [500, 0, 0, 0],
        //                     stops: [
        //                         [0, this.colors[i]],
        //                         [1, this.colorsDark[i]]
        //                     ]
        //                 },
        //                 //color:this.colors[i],
        //                 fillColor: {
        //                     linearGradient: {
        //                         x1: 0,
        //                         y1: 0,
        //                         x2: 0,
        //                         y2: 1
        //                     },
        //                     stops: [
        //                         [0, this.colorsDark[i]],
        //                         [1, this.colorsDark[i] + '00'],
        //                     ]
        //                 },
        //             })
        //         }
        //         i++;
        //     }
        // },
    },
    watch: {
        // data: {
        //     handler(a, b){
        //         this.load();
        //     },
        //     deep: true
        // },
    },
    mounted() {
        //this.load();
    }
};
</script>
