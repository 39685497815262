<template>
    <div class="w-full flex flex-col">
        <ModuleHeader>
            <template v-slot:module> Tops </template>
            <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market.name }}
            </template>
        </ModuleHeader>

        <template>
            <div class="flex flex-col overflow-hidden">
                <span class="sm-title">Más vendidos</span>

                <Swiper
                    :slides="moreSales"
                    :swiperOption="moreSalesOption"
                    refer="moreSales"
                    :delay="defaultOptions.autoplay.delay"
                >
                    <template v-for="(item, index) in moreSales" v-slot:[item.id]>
                        <div class="detail flex">
                            <img
                                class="w-24 h-24 rounded-l-md"
                                :src="item.image.length ? item.image : 'img/default.jpg'"
                                alt=""
                                @error="setAltImg($event)"
                            />
                            <div
                                :content="item.name"
                                v-tippy="{ followCursor: true, arrow: false }"
                                class="w-auto flex-1 py-1 px-3 overflow-hidden cursor-default"
                            >
                                <div class="sm-title text-gray1 truncate mt-0 mb-3">{{ item.name }}</div>
                                <div class="w-full flex justify-between items-center">
                                    <Chip :colorBg="getBGColor(item)" :colorText="getTextColor(item)">
                                        {{ getStockText(item) }}
                                    </Chip>
                                    <Chip
                                        :colorBg="'bg-gray-900'"
                                        :colorText="'text-blue'"
                                        :currency="true"
                                        class="right-0"
                                    >
                                        {{ item.sale_price | numFormat('0,0.00') }}
                                    </Chip>
                                </div>
                                <div class="w-full flex justify-between items-center">
                                    <Kpi class="w-1/2">
                                        <template slot="kpi">Ventas</template>
                                        <template slot="value">{{ item.sales }}</template>
                                    </Kpi>
                                    <Kpi class="w-1/2 text-right pr-2">
                                        <template slot="kpi">Valor</template>
                                        <template slot="value">{{ item.sales_value | numFormat('0,0.00') }} €</template>
                                    </Kpi>
                                </div>
                            </div>
                        </div>
                    </template>
                </Swiper>
                <!-- <div
                    class="swiper-pagination-more my-2 flex flex-wrap content-center items-center justify-center"
                    slot="pagination"
                ></div> -->

                <span class="sm-title">Menos vendidos</span>

                <Swiper
                    :slides="lessSales"
                    :swiperOption="lessSalesOption"
                    refer="lessSales"
                    :delay="defaultOptions.autoplay.delay"
                >
                    <template v-for="(item, index) in lessSales" v-slot:[item.id]>
                        <div class="detail flex">
                            <img
                                class="w-24 h-24 rounded-l-md"
                                :src="item.image.length ? item.image : 'img/default.jpg'"
                                @error="setAltImg($event)"
                                alt=""
                            />
                            <div
                                :content="item.name"
                                v-tippy="{ followCursor: true, arrow: false }"
                                class="w-auto flex-1 py-1 px-3 overflow-hidden cursor-default"
                            >
                                <div class="sm-title text-gray1 truncate mt-0 mb-3">{{ item.name }}</div>
                                <div class="w-full flex justify-between items-center">
                                    <Chip :colorBg="getBGColor(item)" :colorText="getTextColor(item)">
                                        {{ getStockText(item) }}
                                    </Chip>
                                    <Chip :colorBg="'bg-gray-900'" :colorText="'text-blue'" :currency="true">
                                        {{ item.sale_price | numFormat('0,0.00') }}
                                    </Chip>
                                </div>
                                <div class="w-full flex justify-between items-center">
                                    <Kpi class="w-1/2">
                                        <template slot="kpi">Ventas</template>
                                        <template slot="value">{{ item.sales }}</template>
                                    </Kpi>
                                    <Kpi class="w-1/2 text-right pr-2">
                                        <template slot="kpi">Valor</template>
                                        <template slot="value">{{ item.sales_value | numFormat('0,0.00') }} €</template>
                                    </Kpi>
                                </div>
                            </div>
                        </div>
                    </template>
                </Swiper>
                <!-- <div
                    class="swiper-pagination-less mt-2 flex flex-wrap content-center items-center justify-center mb-2"
                    slot="pagination"
                ></div> -->
            </div>
        </template>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Swiper from '@/components/Swiper.vue';
    import Chip from '@/components/Chip.vue';
    import Kpi from '@/components/Kpi.vue';
    import { state } from '@/store';
    import { setAltImg } from '@/utils/helpers';

    export default {
        name: 'ProductTops',
        props: {
            moreSales: {
                type: [Array, Object],
                required: true,
            },
            lessSales: {
                type: [Array, Object],
                required: true,
            },
        },
        components: {
            ModuleHeader,
            Swiper,
            Chip,
            Kpi,
        },
        data() {
            return {
                defaultOptions: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                    // width: 1200,
                    centeredSlides: true,
                    autoplay: {
                        delay: 10000, //delay between slide and slide
                        disableOnInteraction: false,
                    },
                },
                moreSalesOption: {
                    ...this.defaultOptions,
                    pagination: {
                        el: '.swiper-pagination-more',
                        type: 'bullets',
                        clickable: false,
                        dynamicBullets: true,
                    },
                },
                lessSalesOption: {
                    ...this.defaultOptions,
                    pagination: {
                        el: '.swiper-pagination-less',
                        type: 'bullets',
                        clickable: false,
                        dynamicBullets: true,
                    },
                },
            };
        },
        computed: {
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
        },
        methods: {
            setAltImg,
            getItemStyles(item) {
                let out = {
                    bg: 'bg-red-500',
                    txt: 'text-red-900',
                    text: 'Sin Stock',
                };
                if (item.references > 0) {
                    out = {
                        bg: 'bg-green-500',
                        txt: 'text-green-900',
                        text: 'Stock:' + item.reference,
                    };
                }
                return out;
            },
            getBGColor(item) {
                if (item.references > 0) {
                    return 'bg-good-opacity-33';
                } else {
                    return 'bg-bad-opacity-33';
                }
            },
            getTextColor(item) {
                if (item.references > 0) {
                    return 'text-good';
                } else {
                    return 'text-bad';
                }
            },
            getStockText(item) {
                if (item.references > 0) {
                    return 'Stock: ' + item.references;
                } else {
                    return 'Sin Stock';
                }
            },
        },
    };
</script>
