<template>
    <div class="flex flex-col">
        <span
            class="text-xs text-gray1">
            <slot name="kpi"></slot>
        </span>
        <span
            class="text-white"
            :class="{'text-tiny' : largeKpi, 'text-xs' : !largeKpi}">
            <slot name="value"></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'KpiData',
        props: ['largeKpi'],
    }
</script>