<template>
    <div class="inline-flex w-auto px-2 py-0.5 rounded-full" :class="colorBg">
        <span class="text-xs" :class="colorText">
            <slot></slot>
            <span v-if="currency">€</span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'ChipData',
        props: ['colorBg','colorText','currency']
    }
</script>