var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col"},[_c('ModuleHeader',{scopedSlots:_vm._u([{key:"module",fn:function(){return [_c('div',{staticClass:"w-full flex justify-between items-center"},[_vm._v(" Productos ")])]},proxy:true},{key:"period",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.period.from,'DD MMM YY'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"}),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.period.to,'DD MMM YY'))+" ")]},proxy:true},{key:"market",fn:function(){return [_vm._v(" "+_vm._s(_vm.market.name)+" ")]},proxy:true}])}),[_c('div',{staticClass:"w-full flex flex-col tb:flex-row justify-between items-center mb-4"},[_c('div',{staticClass:"flex items-center mb-2 sm:mb-0"},[_c('i',{staticClass:"mdi mdi-magnify text-2xl text-gray2 mr-2"}),_c('div',{staticClass:"relative inline-flex items-center mr-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"bg-input rounded-full border-2 border-box px-4 py-2 text-gray1 w-72 relative",attrs:{"placeholder":"Buscar producto...","disabled":_vm.loading,"readonly":_vm.loading},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},function($event){$event.preventDefault();return _vm.searchProducts.apply(null, arguments)}]}}),(_vm.loading)?_c('loading',{attrs:{"classContainer":"absolute right-0 mr-2 fill-current text-gray-400 w-12 h-12"}}):_vm._e(),(_vm.search !== null && !_vm.loading)?_c('span',{staticClass:"absolute right-0 mr-2 fill-current text-gray-400 right-2 cursor-pointer",on:{"click":_vm.clearSearch}},[_c('i',{staticClass:"mdi mdi-close"})]):_vm._e()],1)]),_c('div',{staticClass:"flex items-end md:items-center"},[_c('div',{staticClass:"flex flex-col md:flex-row items-center"},[_vm._m(0),_c('dropdown',{staticClass:"w-40 sm:w-40",class:{
                            'pointer-events-none': _vm.loading,
                        },attrs:{"close-on-click":true,"class-name":"select-dropdown"}},[_c('template',{slot:"btn"},[_c('span',{staticClass:"text-tiny flex items-center"},[_c('span',{staticClass:"text-xl mr-1",class:'icon-' + _vm.selectedCatFrom.name}),_vm._v(" "+_vm._s(_vm.selectedCatFrom.name ? _vm.selectedCatFrom.name.toUpperCase() : '-')+" ")])]),_c('template',{slot:"body"},_vm._l((_vm.productCategories),function(category){return _c('div',{key:category.id,staticClass:"flex items-center mb-2 py-1 text-tiny",on:{"click":function($event){return _vm.filterCatFrom(category)}}},[_c('div',{staticClass:"w-1 h-6 mr-2",class:{
                                        'bg-casika': _vm.selectedCatFrom.id === category.id,
                                        'bg-gray2': _vm.selectedCatFrom.id !== category.id,
                                    }}),_c('span',{staticClass:"capitalize",class:{
                                        'text-casika': _vm.selectedCatFrom.id === category.id,
                                        'text-gray2': _vm.selectedCatFrom.id !== category.id,
                                    }},[_vm._v(_vm._s(category.name ? category.name : 'Vacío (-)'))])])}),0)],2)],1),_c('div',{staticClass:"flex flex-col md:flex-row items-center"},[_c('span',{staticClass:"sm-title text-blue mx-3"},[_vm._v("Actual")]),_c('dropdown',{staticClass:"w-40 sm:w-40",class:{
                            'pointer-events-none': _vm.loading,
                        },attrs:{"close-on-click":true,"class-name":"select-dropdown"}},[_c('template',{slot:"btn"},[_c('span',{staticClass:"text-tiny flex items-center"},[_c('span',{staticClass:"text-xl mr-1",class:'icon-' + _vm.selectedCatTo.name}),_vm._v(" "+_vm._s(_vm.selectedCatTo.name ? _vm.selectedCatTo.name.toUpperCase() : 'Todos')+" ")])]),_c('template',{slot:"body"},_vm._l((_vm.productCategories),function(category){return _c('div',{key:category.id,staticClass:"flex items-center mb-2 py-1 text-tiny",on:{"click":function($event){return _vm.filterCatTo(category)}}},[_c('div',{staticClass:"w-1 h-6 mr-2",class:{
                                        'bg-casika': _vm.selectedCatTo.id === category.id,
                                        'bg-gray2': _vm.selectedCatTo.id !== category.id,
                                    }}),_c('span',{staticClass:"capitalize",class:{
                                        'text-casika': _vm.selectedCatTo.id === category.id,
                                        'text-gray2': _vm.selectedCatTo.id !== category.id,
                                    }},[_vm._v(" "+_vm._s(category.name ? category.name : 'Todos')+" ")])])}),0)],2)],1)])]),_c('AdvancedTable',{attrs:{"items":_vm.filteredData,"fields":_vm.fields,"loading":_vm.loading,"styleCont":'min-height: 76vh;',"responsive":true},scopedSlots:_vm._u([{key:"product_item",fn:function(ref){
                                    var value = ref.value;
                                    var item = ref.item;
return [_c('div',{staticClass:"flex items-center"},[(value.days_last_change > 3)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ followCursor: true, arrow: false }),expression:"{ followCursor: true, arrow: false }"}],staticClass:"f-center m-2 w-12 h-12 rounded-full text-module",class:_vm.productCategory(value.current_category, value.alert),attrs:{"content":value.current_category}},[_c('span',{staticClass:"text-2xl",class:'icon-' + value.current_category})]):_c('div',{staticClass:"flex justify-around items-center"},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ followCursor: true, arrow: false }),expression:"{ followCursor: true, arrow: false }"}],staticClass:"f-center m-1 w-8 h-8 rounded-full text-module bg-gray2 bg-opacity-50 text-gray1",attrs:{"content":value.previous_category}},[(!value.previous_category)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ followCursor: true, arrow: false }),expression:"{ followCursor: true, arrow: false }"}],staticClass:"mdi mdi-help text-lg",attrs:{"content":'Categoría desconocida'}}):_c('span',{staticClass:"text-lg",class:'icon-' + value.previous_category})]),_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({ followCursor: true, arrow: false }),expression:"{ followCursor: true, arrow: false }"}],staticClass:"f-center m-1 w-8 h-8 rounded-full text-module",class:_vm.productCategory(value.current_category, value.alert),attrs:{"content":value.current_category}},[(!value.current_category)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ followCursor: true, arrow: false }),expression:"{ followCursor: true, arrow: false }"}],staticClass:"mdi mdi-help text-lg text-gray1",attrs:{"content":'Categoría desconocida'}}):_c('span',{staticClass:"text-lg",class:'icon-' + value.current_category})])]),_c('div',{staticClass:"bg-white rounded-lg w-16 h-16 overflow-hidden mx-1"},[_c('img',{class:{
                                'w-full h-full object-cover': value.image,
                                'w-16 rounded-lg h-full object-cover': !value.image,
                            },attrs:{"src":value.image ? value.image : 'img/default.jpg',"alt":""},on:{"error":function($event){return _vm.setAltImg($event)}}})]),_c('div',{staticClass:"flex flex-col justify-start text-left mx-2"},[_c('span',{staticClass:"md-title text-white"},[_vm._v(_vm._s(value.sku))]),_c('div',{staticClass:"whitespace-normal w-32 sm-title"},[_vm._v(" "+_vm._s(value.name)+" ")])])])]}},{key:"sales_general",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v(_vm._s(_vm._f("numFormat")(item.sales_value,'0,0a'))+" €")]),_c('div',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm._f("numFormat")(item.sale_percent,'0.00'))+"%")])])]}},{key:"ctr",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v("--")])])]}},{key:"visits",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v(_vm._s(_vm._f("numFormat")(item.visits,'0,0a')))]),_c('div',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm._f("numFormat")(item.conversion,'0,00a'))+"%")])])]}},{key:"stock",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"flex items-center ml-6"},[(item.stock > 0)?_c('i',{staticClass:"mdi mdi-check-circle mr-2 text-catBlue"}):_c('i',{staticClass:"mdi mdi-close-circle mr-2 text-bad"}),_c('div',{staticClass:"md-title text-white",class:{ 'text-catBlue': item.stock > 0, 'text-gray2': item.stock <= 0 }},[_vm._v(" "+_vm._s(_vm._f("numFormat")(item.stock,'0,0a'))+" ")])])]}}])})]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-end"},[_c('span',{staticClass:"sm-title text-gray1 mr-3"},[_vm._v("Anterior")]),_c('span',{staticClass:"xs-title text-gray2 mr-3"},[_vm._v("(Solo para comparar)")])])}]

export { render, staticRenderFns }