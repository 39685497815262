<template>
    <div class="w-full flex flex-col">
        <ModuleHeader>
            <template v-slot:module>
                <div class="w-full flex justify-between items-center">Categorías</div>
            </template>
            <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market.name }}
            </template>
        </ModuleHeader>

        <template>
            <!-- Filters -->
            <div class="w-full flex flex-col sm:flex-row items-center sm:justify-between mb-4">
                <div class="flex items-center">
                    <div
                        class="flex items-center mb-2 sm:mb-0"
                        :class="{
                            hidden: selectedType === 1,
                        }"
                    >
                        <i class="mdi mdi-magnify text-2xl text-gray2 mr-2"></i>
                        <input
                            v-model="search"
                            placeholder="Buscar categoría..."
                            class="bg-input rounded-full border border-box px-4 py-2 text-gray1 w-72"
                            :disabled="loadingSub"
                            :readonly="loadingSub"
                        />
                    </div>
                    <ButtonTabs :createdTabs="tabs" @selectedTab="selectedTab" />
                </div>
                <!-- <toggle-button
                    v-model="catFilter"
                    @change="setCatFilter"
                    :value="true"
                    :labels="{checked: 'Solo principales', unchecked: 'Todas'}"
                    :color="{checked: '#13F2E355', unchecked: '#b6c0d429', disabled: '#CCCCCC'}"
                    :switch-color="{checked: '#13F2E3', unchecked: '#79849A', disabled: '#CCCCCC'}"
                    :width="120"  
                /></toggle-button> -->
            </div>

            <!-- SubCategories boxes -->
            <div
                v-if="selectedType === 1 && loadingSub"
                class="flex flex-wrap items-center content-center justify-center w-full"
            >
                <Loading />
            </div>
            <div
                class="flex w-full overflow-x-scroll overflow-y-hidden h-28"
                v-if="selectedType === 1 && !loadingCat && !loadingSub"
            >
                <div class="w-200 inline-flex relative">
                    <Box
                        v-for="(subcategory, index) in filterCategories"
                        :key="subcategory.id + '-' + index"
                        :class="{
                            active: filteredCategory === subcategory.id,
                        }"
                        classContainer="box box-category w-52 inline-block mx-1"
                    >
                        <template v-slot:content>
                            <div
                                @click.prevent="setCategory(subcategory.id)"
                                class="flex justify-between items-center py-0 cursor-pointer h-full"
                            >
                                <div class="w-auto extra:w-5/12 mr-2">
                                    <img
                                        class="w-16 rounded-lg h-full object-cover mr-2"
                                        :src="getImgSrc(subcategory.id)"
                                        alt=""
                                        @error="setAltImg($event)"
                                    />
                                    <!--                                    <img-->
                                    <!--                                        class="w-16 rounded-lg h-full object-cover mr-2"-->
                                    <!--                                        src="img/default.jpg"-->
                                    <!--                                        alt=""-->
                                    <!--                                    />-->
                                </div>
                                <div class="w-auto extra:w-7/12">
                                    <div class="md-title font-medium text-right extra:text-left px-1">
                                        {{ subcategory.name }}
                                    </div>
                                    <Chip
                                        v-if="subcategory.references > 0"
                                        :colorBg="'bg-catBlue-opacity-33'"
                                        :colorText="'text-catBlue'"
                                    >
                                        {{ subcategory.references | numFormat('0,0') }} referencias
                                    </Chip>
                                    <Chip v-else :colorBg="'bg-regular-opacity-33'" :colorText="'text-regular'">
                                        Sin referencias
                                    </Chip>
                                    <!-- FILTERED CATEGORY {{filteredCategory}} -->
                                </div>
                            </div>
                        </template>
                    </Box>
                </div>
            </div>

            <!-- TABLE -->
            <Table
                :items="selectedType === 1 ? filterSubCategories : filterCategories"
                :fields="fields"
                :loading="selectedType === 1 ? loadingSub : loadingCat"
                :styleCont="getStyleCont"
            >
                <template v-slot:image="{ item }">
                    <div class="flex">
                        <img
                            class="w-16 rounded-lg h-full object-cover"
                            :src="getImgSrc(item.image.slice(0, -9))"
                            :alt="item.id"
                            @error="setAltImg($event)"
                        />
                    </div>
                </template>
                <template v-slot:name="{ value }">
                    <div class="text-left pl-4">{{ value }}</div>
                </template>
                <template v-slot:references="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.references | numFormat('0,0') }}</div>
                        <div class="sm-title">{{ item.references_percent | numFormat('0,0.0') }} %</div>
                    </div>
                </template>
                <template v-slot:visits="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.visits | numFormat('0,0') }}</div>
                        <div class="sm-title">{{ item.visits_percent | numFormat('0.0') }} %</div>
                    </div>
                </template>
                <template v-slot:sales_value="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.sales_value | numFormat('0,0.0a') }} €</div>
                        <div class="sm-title">{{ item.sales_value_percent | numFormat('0.0') }} %</div>
                    </div>
                </template>
                <template v-slot:articles="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.articles | numFormat('0,0') }}</div>
                        <div v-if="selectedType === 0" class="sm-title">{{ item.articles_percent | numFormat('0.0') }} %</div>
                        <div v-else class="sm-title lowercase">{{ item.articles_percent | numFormat('0.0') }} %/{{ item.category_name }}</div>
                    </div>
                </template>
            </Table>
        </template>
    </div>
</template>

<script>
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import ButtonTabs from '@/components/elements/ButtonTabs.vue';
    import Table from '@/components/elements/Table.vue';
    import Box from '@/components/Box.vue';
    import Chip from '@/components/Chip.vue';
    import { actions, state } from '@/store';
    import Loading from '@/components/elements/Loading';
    import { setAltImg } from '@/utils/helpers';

    export default {
        name: 'ProductTableCat',
        props: {
            loadingSub: {
                type: Boolean,
                required: false,
                default: false,
            },
            loadingCat: {
                type: Boolean,
                required: false,
                default: false,
            },
            categories: {
                type: [Array, Object],
                required: true,
                default: () => [],
            },
            subcategories: {
                type: [Array, Object],
                required: true,
                default: () => [],
            },
        },
        components: {
            Loading,
            ModuleHeader,
            ButtonTabs,
            Table,
            Box,
            Chip,
        },
        data() {
            return {
                catFilter: true,
                search: '',
                imgError: false,
                image: '',
                tabs: [
                    {
                        id: 0,
                        tab: 'Categorías',
                    },
                    {
                        id: 1,
                        tab: 'Subcategorías',
                    },
                ],
                fields: [
                    {
                        label: 'Imagen',
                        key: 'image',
                        width: 60,
                    },
                    {
                        label: 'Categoría',
                        key: 'name',
                        width: 150,
                    },
                    {
                        label: 'ID',
                        key: 'id',
                        width: 120,
                    },
                    {
                        label: 'Nº Ref. / %',
                        key: 'references',
                        width: 120,
                        postfix: '%',
                        format: 'fixed',
                    },
                    // {
                    //     label: 'Media ref.',
                    //     key: 'references_average',
                    //     width: 120,
                    //     format: 'fixed',
                    // },
                    {
                        label: 'Evo. Ref.',
                        key: 'references_evo',
                        width: 120,
                        format: 'fixed',
                    },
                    // {
                    //     label: '% Incremento',
                    //     key: 'sales_value_percent',
                    //     width: 120,
                    //     postfix: '%',
                    //     format: 'fixed',
                    // },
                    {
                        label: 'Nº artículos/ %',
                        key: 'articles',
                        width: 120,
                        postfix: '%',
                        format: 'fixed',
                    },
                    {
                        label: 'Visitas/ %',
                        key: 'visits',
                        width: 120,
                        postfix: '%',
                        format: 'fixed',
                    },
                    {
                        label: 'Ventas / %',
                        key: 'sales_value',
                        width: 120,
                        postfix: '%',
                        format: 'fixed',
                    },
                    {
                        label: '% Conversión',
                        key: 'conversion',
                        width: 120,
                        postfix: '%',
                        format: 'fixed',
                    },
                ],
                filteredCategory: '',
                selectedType: 0,
            };
        },
        computed: {
            getStyleCont() {
                return this.selectedType === 1 ? 'max-height: 68vh;' : 'max-height: 78vh;';
            },
            filterCategories() {
                if (this.selectedType === 1) {
                    return this.categories.filter((item, index) => {
                        if (item.references > 0) {
                            return this.transformSearch(item.name.toLowerCase()).includes(
                                this.transformSearch(this.search.toLowerCase()),
                            );
                        }
                    });
                }
                return this.categories.filter(item => {
                    return this.transformSearch(item.name.toLowerCase()).includes(
                        this.transformSearch(this.search.toLowerCase()),
                    );
                });
            },
            filterSubCategories() {
                if (!this.subcategories.length) {
                    return [];
                }

                if (this.filteredCategory === '') {
                    return this.subcategories;
                }

                return this.subcategories.filter(item => {
                    return item.category_id === this.filteredCategory;
                });
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
        },
        methods: {
            setAltImg,
            getImgSrc(image) {
                return 'img/categories/' + image + '.png';
            },
            imgLoadError(image) {
                this.imgError = true;
                this.image = image;
            },
            setCategory(category) {
                if (this.filteredCategory === category) {
                    this.filteredCategory = '';
                    return;
                }

                this.filteredCategory = category;
                this.subcategories.filter(item => {
                    //console.log( item.category_id, category, item.category_id === category)
                    return item.category_id === category;
                });
                //console.log('category :>> ', category);
            },
            selectedTab(tab) {
                this.selectedType = tab;
            },
            transformSearch(string) {
                return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            },
            setCatFilter(catFilter) {
                actions.setCatFilter('principales');
            },
        },
        watch: {
            filteredCategory(n, o) {
                this.filteredCategory = n;
            },
        },
    };
</script>

<style>
    .box.active {
        @apply border-blue border-2;
        box-sizing: border-box;
        padding: 6px 8px;
    }

    .box-category .box-container {
        height: 100%;
    }
    .v-switch-label {
        font-weight: 300 !important;
    }
</style>
