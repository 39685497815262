var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.styleCont),attrs:{"role":"products","aria-labelledby":"caption","tabindex":"0"}},[(!_vm.loading)?_c('table',{class:_vm.responsive ? 'zResponsive' : ''},[_c('thead',[_c('tr',_vm._l((_vm.fields),function(field,index){return _c('th',{key:("heading-" + (field.id ? field.id + index : index)),class:{
                        'cursor-pointer': field.sortable,
                        'cursor-default': !field.sortable,
                    },attrs:{"scope":"col"},on:{"click":function($event){field.sortable ? _vm.sort(field.sortable) : undefined}}},[_vm._v(" "+_vm._s(field.label)+" "),(field.sortable)?_c('span',{staticClass:"mdi mdi-menu-down text-tiny tracking-tighter leading-nonew",class:{ 'text-catBlue': _vm.currentSort === field.sortable && _vm.currentSortDir === 'desc' }}):_vm._e(),(field.sortable)?_c('span',{staticClass:"mdi mdi-menu-up text-tiny tracking-tighter leading-nonew",class:{ 'text-catBlue': _vm.currentSort === field.sortable && _vm.currentSortDir === 'asc' }}):_vm._e()])}),0)]),_c('tbody',[(_vm.sortedItems.length)?_vm._l((_vm.sortedItems),function(item){return _c('tr',{key:item.id + Math.random() * 100},_vm._l((_vm.fields),function(field,index){return _c('td',{key:("col-" + (field.id ? field.id + index : index)),staticClass:"px-0 py-4 whitespace-nowrap",class:{
                            'first-table-column': field.width === 300,
                            'bg-good-opacity-20':
                                (item.sale_percent > 1 && field.key === 'sale_percent') ||
                                (item.profit_growth > 1 && field.key === 'profit_growth'),
                        },style:({ minWidth: field.width + 'px' }),attrs:{"data-title-mobile":field.label}},[_vm._t(field.key,function(){return [(field.prefix)?_c('span',{staticClass:"relative inline-block w-auto"},[_vm._v(" "+_vm._s(field.prefix)+" ")]):_vm._e(),(field.format === 'fixed' && item[field.key] !== parseInt(item[field.key], 10))?_c('span',{staticClass:"relative inline-block w-auto"},[_vm._v(" "+_vm._s(_vm._f("numFormat")(item[field.key],'0,0.0a'))+" ")]):_c('span',{staticClass:"relative inline-block w-auto"},[_vm._v(" "+_vm._s(_vm._f("numFormat")(item[field.key],'0,0'))+" ")]),(field.postfix)?_c('span',{staticClass:"relative inline-block pl-1"},[_c('small',[_vm._v(_vm._s(field.postfix))])]):_vm._e()]},{"index":index,"item":item,"value":item[field.key],"field":field})],2)}),0)}):[_c('tr',[_c('td',{staticClass:"text-center h-40",staticStyle:{"color":"rgba(121, 132, 154, var(--tw-text-opacity))"},attrs:{"colspan":_vm.fields.length}},[_vm._v(" SIN RESULTADOS ")])])]],2)]):_c('loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }