<template>
    <div role="products" aria-labelledby="caption" tabindex="0" :style="styleCont">
        <table v-if="!loading" :class="responsive ? 'zResponsive' : ''">
            <thead>
                <tr>
                    <th
                        v-for="(field, index) in fields"
                        :key="`heading-${field.id ? field.id + index : index}`"
                        scope="col"
                        :class="{
                            'cursor-pointer': field.sortable,
                            'cursor-default': !field.sortable,
                        }"
                        @click="field.sortable ? sort(field.sortable) : undefined"
                    >
                        {{ field.label }}
                        <span
                            v-if="field.sortable"
                            class="mdi mdi-menu-down text-tiny tracking-tighter leading-nonew"
                            :class="{ 'text-catBlue': currentSort === field.sortable && currentSortDir === 'desc' }"
                        ></span>
                        <span
                            v-if="field.sortable"
                            class="mdi mdi-menu-up text-tiny tracking-tighter leading-nonew"
                            :class="{ 'text-catBlue': currentSort === field.sortable && currentSortDir === 'asc' }"
                        ></span>
                    </th>
                </tr>
            </thead>

            <tbody>
                <template v-if="sortedItems.length">
                    <tr v-for="item in sortedItems" :key="item.id + Math.random() * 100">
                        <td
                            v-for="(field, index) in fields"
                            :key="`col-${field.id ? field.id + index : index}`"
                            class="px-0 py-4 whitespace-nowrap"
                            :style="{ minWidth: field.width + 'px' }"
                            :data-title-mobile="field.label"
                            :class="{
                                'first-table-column': field.width === 300,
                                'bg-good-opacity-20':
                                    (item.sale_percent > 1 && field.key === 'sale_percent') ||
                                    (item.profit_growth > 1 && field.key === 'profit_growth'),
                            }"
                        >
                            <slot :name="field.key" :index="index" :item="item" :value="item[field.key]" :field="field">
                                <span v-if="field.prefix" class="relative inline-block w-auto">
                                    {{ field.prefix }}
                                </span>
                                <span
                                    v-if="field.format === 'fixed' && item[field.key] !== parseInt(item[field.key], 10)"
                                    class="relative inline-block w-auto"
                                >
                                    {{ item[field.key] | numFormat('0,0.0a') }}
                                </span>
                                <span v-else class="relative inline-block w-auto">
                                    {{ item[field.key] | numFormat('0,0') }}
                                </span>
                                <span v-if="field.postfix" class="relative inline-block pl-1">
                                    <small>{{ field.postfix }}</small>
                                </span>
                            </slot>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td
                            :colspan="fields.length"
                            class="text-center h-40"
                            style="color: rgba(121, 132, 154, var(--tw-text-opacity))"
                        >
                            SIN RESULTADOS
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <loading v-else />
    </div>
</template>

<script>
    import Loading from '@/components/elements/Loading';
    import EventBus from '@/utils/event-bus';

    export default {
        name: 'AdvancedTable',
        components: { Loading },
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            items: {
                type: [Array, Object],
                default: () => [],
            },
            fields: {
                type: [Array, Object],
                default: () => [],
            },
            styleCont: {
                type: String,
                required: false,
                default: '',
            },
            responsive: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data() {
            return {
                currentSort: 'currency_prevision',
                currentSortDir: 'asc',
            };
        },
        methods: {
            sort: function (s) {
                //if s === current sort, reverse
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
                EventBus.$emit('sorted', {
                    sortBy: this.currentSort,
                    sortDir: this.currentSortDir,
                });
            },
        },
        computed: {
            sortedItems() {
                return this.items;
                /*return [].slice.call(this.items).sort((a,b) => {
                let modifier = 1;
                if(this.currentSortDir === 'desc') modifier = -1;
                if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                return 0;
              });*/
            },
        },
    };
</script>

<style>
    table {
        color: white;
        white-space: nowrap;
        margin: 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;
        table-layout: fixed;
    }

    table td,
    table th {
        /* border: 1px solid black; */
        padding: 0.5rem 1rem;
    }

    table thead th {
        padding: 3px;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 25vw;
    }

    table td {
        padding: 4px 125px;
        text-align: center;
    }

    table tbody th {
        font-weight: 100;
        text-align: left;
        position: relative;
    }

    table thead th:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        @apply bg-module text-gray2 text-xs;
    }

    table thead tr th {
        @apply bg-module text-gray2 text-xs;
    }

    table th:first-child {
        /* background-color: blue; */
    }

    table tbody td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        @apply bg-module;
    }

    @media only screen and (max-width: 600px) {
        table thead th:first-child {
            position: relative;
        }

        table tbody td:first-child {
            position: relative;
        }
    }

    caption {
        text-align: left;
        padding: 0.25rem;
        position: sticky;
        left: 0;
    }

    .first-table-column {
        box-shadow: 10px 0 13px -10px #111621b5;
        border-bottom: 2px solid #1d2532 !important;
    }

    [role='products'][aria-labelledby][tabindex] {
        width: 100%;
        max-height: 75vh;
        overflow: auto;
    }

    [role='products'][aria-labelledby][tabindex]:focus {
        /* box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5); */
        outline: 0;
    }

    tr {
        @apply relative py-2 px-5 mb-3;
    }

    tr:nth-child(even) {
        @apply bg-box;
    }

    table th:first-child {
        @apply bg-module;
    }

    table .first-column:nth-child(even) {
        @apply bg-box;
    }

    /* RESPONSIVE TABLE - SHOW COLS IN ROWS - EASIER READING IN MOBILE / TABLET */
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
        table.zResponsive [role='products'][aria-labelledby][tabindex] {
            overflow-x: hidden;
        }

        table.zResponsive,
        table.zResponsive thead,
        table.zResponsive tbody,
        table.zResponsive th,
        table.zResponsive td,
        table.zResponsive tr {
            display: block !important;
        }

        table.zResponsive tbody td:first-child {
            margin: auto 0;
            min-width: auto !important;
            background-color: transparent;
        }

        table.zResponsive thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        table.zResponsive tr {
            margin: 0 0 1rem 0;
            padding: 0.5rem 0 !important;
        }

        table.zResponsive td {
            border: none;
            border-bottom: 1px solid #111621b5;
            position: relative;
            padding-left: 20% !important;
            justify-content: center;
            align-items: center;
            place-content: center;
        }

        table.zResponsive td:before {
            position: absolute;
            top: calc(50% - 0.7em);
            left: 10px;
            text-align: left;
            width: 50%;
            padding-right: 10px;
            white-space: nowrap;
        }

        table.zResponsive td[data-title-mobile='Producto'] span[class*=' icon-'] {
            padding-top: 10px !important;
        }

        table.zResponsive td[data-title-mobile='Producto'] * {
            display: block;
            text-align: center;
            align-content: center;
            margin: 0 auto 5px auto;
        }
        table.zResponsive td[data-title-mobile='Stock'] {
            display: flex !important;
        }

        table.zResponsive td[data-title-mobile='Producto'] .sm-title {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
        }

        /*
        Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
        */
        table.zResponsive td:before {
            content: attr(data-title-mobile);
        }
    }
</style>
