var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-auto px-3 py-3"},[_c('Header'),[_c('div',{staticClass:"grid grid-cols-12 grid-flow-row extra:grid-flow-col gap-3 w-full h-full flex-1"},[_c('div',{staticClass:"\n                    module\n                    row-span-auto\n                    sm:row-span-1\n                    extra:row-span-1\n                    col-span-12\n                    sm:col-span-7\n                    extra:col-span-3\n                "},[_c('ModuleTab'),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.computedWareHouseQuery,"data":_vm.warehouse,"config":{ url: 'storehouse' }},on:{"update:data":function($event){_vm.warehouse=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loadingWarehouse = ref.loading;
return [(loadingWarehouse)?_c('Loading'):_c('ProductWarehouse',{attrs:{"data":_vm.computedWareHouse}})]}}])})],1),_c('div',{staticClass:"module col-span-1 col-span-12 sm:col-span-5 extra:col-span-3"},[_c('ModuleTab'),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.computedProductsTopsQuery,"data":_vm.productsTop,"config":{ url: 'tops' }},on:{"update:data":function($event){_vm.productsTop=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loadingTops = ref.loading;
return [(loadingTops)?_c('Loading'):_c('ProductTops',{attrs:{"moreSales":_vm.computedMoreSales,"lessSales":_vm.computedLessSales}})]}}])})],1),_c('div',{staticClass:"module row-span-auto sm:row-span-2 col-span-12 extra:col-span-9"},[_c('ModuleTab'),_c('div',{staticClass:"\n                        relative\n                        md:absolute\n                        top-0\n                        right-0\n                        w-full\n                        extra:w-4/12\n                        flex\n                        justify-center\n                        sm:justify-end\n                        items-center\n                        z-50\n                        mt-2\n                        mr-4\n                    "},[_c('Download',{ref:"productsExcel",attrs:{"action":"Request","model":"ApiRequest","immediate":false,"sensitive":false,"query":_vm.computedProductsQuery,"config":{ url: 'products-excel' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [(_vm.loadingExcel)?_c('div',{staticClass:"w-full h-full relative opacity-50 flex justify-center items-center"},[_c('img',{staticClass:"w-16",attrs:{"src":"img/loading.svg","alt":""}}),_c('span',{staticClass:"text-gray1 text-xs"},[_vm._v("El archivo se descargará automáticamente")])]):_c('ButtonSecondary',{on:{"buttonAction":_vm.downloadExcel}},[_c('i',{staticClass:"mdi mdi-download text-base mr-1"}),_vm._v(" Descargar excel ")])]}}])}),_c('ButtonTabs',{attrs:{"createdTabs":_vm.tabs},on:{"selectedTab":_vm.selectedTab}})],1),_c('All',{ref:"ProductsQuery",attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":false,"query":_vm.computedProductsQuery,"data":_vm.products,"config":{ url: 'products' }},on:{"update:data":[function($event){_vm.products=$event},_vm.gotProducts]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loadingProducts = ref.loading;
return [_c('div',{staticClass:"relative flex flex-wrap"},[_c('ProductTablePro',{directives:[{name:"show",rawName:"v-show",value:(_vm.table === 0),expression:"table === 0"}],staticClass:"relative",attrs:{"data":_vm.products.data.products,"total":_vm.products.data.total,"loading":loadingProducts}}),_c('div',{staticClass:"relative block w-full"},[(
                                    !loadingProducts &&
                                    _vm.table === 0 &&
                                    _vm.paginate.totalPages > 0 &&
                                    _vm.paginate.totalPages > 1
                                )?_c('div',{staticClass:"\n                                    md:flex md:flex-wrap md:justify-center md:mx-auto md:w-full md:mt-0 md:mb-0\n                                    justify-items-center\n                                    items-center\n                                    text-center\n                                "},[_c('button',{staticClass:"md:inline md:w-auto md:mx-0 bg-transparent text-white font-medium mx-1",class:{
                                        'opacity-50 pointer-events-none': _vm.paginate.page <= 1,
                                    },on:{"click":_vm.pageDown}},[_c('i',{staticClass:"mdi mdi-chevron-left mr-2 text-lg"})]),_c('span',{staticClass:"\n                                        md:flex\n                                        text-gray1\n                                        md:inline md:w-auto md:mx-0\n                                        mx-1\n                                        text-center\n                                        justify-items-center\n                                    "},[_c('input',{staticClass:"\n                                            bg-transparent\n                                            text-right\n                                            pr-1\n                                            w-8\n                                            focus focus:border-gray-300 focus:border-2\n                                        ",attrs:{"type":"number","min":"1","max":_vm.paginate.totalPages},domProps:{"value":_vm.paginate.page},on:{"input":function($event){$event.preventDefault();return _vm.jumpToPage.apply(null, arguments)}}}),_vm._v(" / "),_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm._f("numFormat")(_vm.paginate.totalPages,'0,0'))+" ")])]),_c('button',{staticClass:"\n                                        bg-transparent\n                                        mx-1\n                                        text-white\n                                        font-medium\n                                        md:inline md:w-auto md:mx-0 md:pl-1\n                                    ",class:{
                                        'opacity-50 pointer-events-none': _vm.paginate.page === _vm.paginate.totalPages,
                                    },on:{"click":_vm.pageUp}},[_c('i',{staticClass:"mdi mdi-chevron-right mr-2 text-lg"})])]):_vm._e(),(!loadingProducts && _vm.table === 0 && _vm.products.data.total > 0)?_c('div',{staticClass:"\n                                    md:flex\n                                    md:flex-wrap\n                                    md:justify-center\n                                    md:mr-0\n                                    md:mt-1\n                                    md:right-0\n                                    md:absolute\n                                    md:text-right\n                                    md:top-0\n                                    text-center\n                                    mt-0\n                                "},[_c('span',{staticClass:"text-sm md:inline md:w-auto w-full block text-gray1"},[_vm._v(" Mostrando: "+_vm._s(_vm._f("numFormat")(_vm.fromReg,'0,0'))+" al "+_vm._s(_vm._f("numFormat")(_vm.toReg,'0,0'))+" de "+_vm._s(_vm._f("numFormat")(_vm.products.data.total,'0,0'))+" ")])]):_vm._e()])],1)]}}])}),_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.computedCategoriesQuery,"data":_vm.categories,"config":{ url: 'categories' }},on:{"update:data":function($event){_vm.categories=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var loadingCategories = ref.loading;
return [_c('All',{attrs:{"action":"Request","model":"ApiRequest","immediate":true,"sensitive":true,"query":_vm.computedSubCategoriesQuery,"data":_vm.subcategories,"config":{ url: 'subcategories' }},on:{"update:data":function($event){_vm.subcategories=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var loadingSubcategories = ref.loading;
return [_c('ProductTableCat',{directives:[{name:"show",rawName:"v-show",value:(_vm.table === 1),expression:"table === 1"}],attrs:{"categories":_vm.categories.data,"subcategories":_vm.subcategories.data,"loadingSub":loadingSubcategories,"loadingCat":loadingCategories}})]}}],null,true)})]}}])})],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }