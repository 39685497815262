<template>
    <div class="w-full flex flex-col">
        <ModuleHeader>
            <template v-slot:module>
                <div class="w-full flex justify-between items-center">
                    Productos
                    <!-- <div class="w-full extra:w-4/12 flex justify-center extra:justify-end">
                        <ButtonTabs
                            :createdTabs="tabs" />
                    </div> -->
                </div>
            </template>
            <template v-slot:period>
                {{ period.from | moment('DD MMM YY') }} <i class="mdi mdi-arrow-right"></i>
                {{ period.to | moment('DD MMM YY') }}
            </template>
            <template v-slot:market>
                {{ market.name }}
            </template>
        </ModuleHeader>

        <template>
            <div class="w-full flex flex-col tb:flex-row justify-between items-center mb-4">
                <div class="flex items-center mb-2 sm:mb-0">
                    <i class="mdi mdi-magnify text-2xl text-gray2 mr-2"></i>

                    <div class="relative inline-flex items-center mr-1">
                        <input
                            v-model="search"
                            @input.prevent="searchProducts"
                            placeholder="Buscar producto..."
                            class="bg-input rounded-full border-2 border-box px-4 py-2 text-gray1 w-72 relative"
                            :disabled="loading"
                            :readonly="loading"
                        />

                        <loading
                            v-if="loading"
                            classContainer="absolute right-0 mr-2 fill-current text-gray-400 w-12 h-12"
                        />
                        <span
                            @click="clearSearch"
                            v-if="search !== null && !loading"
                            class="absolute right-0 mr-2 fill-current text-gray-400 right-2 cursor-pointer"
                        >
                            <i class="mdi mdi-close"></i>
                        </span>
                    </div>
                </div>
                <div class="flex items-end md:items-center">
                    <div class="flex flex-col md:flex-row items-center">
                        <div class="flex flex-col items-end">
                            <span class="sm-title text-gray1 mr-3">Anterior</span>
                            <span class="xs-title text-gray2 mr-3">(Solo para comparar)</span>
                        </div>
                        
                        <dropdown
                            :close-on-click="true"
                            class-name="select-dropdown"
                            class="w-40 sm:w-40"
                            :class="{
                                'pointer-events-none': loading,
                            }"
                        >
                            <template slot="btn">
                                <span class="text-tiny flex items-center">
                                    <span :class="'icon-' + selectedCatFrom.name" class="text-xl mr-1"></span>
                                    {{ selectedCatFrom.name ? selectedCatFrom.name.toUpperCase() : '-' }}
                                </span>
                            </template>

                            <template slot="body">
                                <div
                                    v-for="category in productCategories"
                                    :key="category.id"
                                    class="flex items-center mb-2 py-1 text-tiny"
                                    @click="filterCatFrom(category)"
                                >
                                    <div
                                        class="w-1 h-6 mr-2"
                                        :class="{
                                            'bg-casika': selectedCatFrom.id === category.id,
                                            'bg-gray2': selectedCatFrom.id !== category.id,
                                        }"
                                    ></div>
                                    <span
                                        class="capitalize"
                                        :class="{
                                            'text-casika': selectedCatFrom.id === category.id,
                                            'text-gray2': selectedCatFrom.id !== category.id,
                                        }"
                                        >{{ category.name ? category.name : 'Vacío (-)' }}</span
                                    >
                                </div>
                            </template>
                        </dropdown>
                    </div>

                    <div class="flex flex-col md:flex-row items-center">
                        <span class="sm-title text-blue mx-3">Actual</span>
                        <dropdown
                            :close-on-click="true"
                            class-name="select-dropdown"
                            class="w-40 sm:w-40"
                            :class="{
                                'pointer-events-none': loading,
                            }"
                        >
                            <template slot="btn">
                                <span class="text-tiny flex items-center">
                                    <span :class="'icon-' + selectedCatTo.name" class="text-xl mr-1"></span>
                                    {{ selectedCatTo.name ? selectedCatTo.name.toUpperCase() : 'Todos' }}
                                </span>
                            </template>

                            <template slot="body">
                                <div
                                    v-for="category in productCategories"
                                    :key="category.id"
                                    class="flex items-center mb-2 py-1 text-tiny"
                                    @click="filterCatTo(category)"
                                >
                                    <div
                                        class="w-1 h-6 mr-2"
                                        :class="{
                                            'bg-casika': selectedCatTo.id === category.id,
                                            'bg-gray2': selectedCatTo.id !== category.id,
                                        }"
                                    ></div>
                                    <span
                                        class="capitalize"
                                        :class="{
                                            'text-casika': selectedCatTo.id === category.id,
                                            'text-gray2': selectedCatTo.id !== category.id,
                                        }"
                                    >
                                        {{ category.name ? category.name : 'Todos' }}
                                    </span>
                                </div>
                            </template>
                        </dropdown>
                    </div>
                </div>
            </div>

            <!-- TABLE -->
            <AdvancedTable
                :items="filteredData"
                :fields="fields"
                :loading="loading"
                :styleCont="'min-height: 76vh;'"
                :responsive="true"
            >
                <template v-slot:product_item="{ value, item }">
                    <div class="flex items-center">
                        <div
                            v-if="value.days_last_change > 3"
                            :content="value.current_category"
                            v-tippy="{ followCursor: true, arrow: false }"
                            class="f-center m-2 w-12 h-12 rounded-full text-module"
                            :class="productCategory(value.current_category, value.alert)"
                        >
                            <span :class="'icon-' + value.current_category" class="text-2xl"></span>
                        </div>
                        <div v-else class="flex justify-around items-center">
                            <div
                                :content="value.previous_category"
                                v-tippy="{ followCursor: true, arrow: false }"
                                class="f-center m-1 w-8 h-8 rounded-full text-module bg-gray2 bg-opacity-50 text-gray1"
                            >
                                <span
                                    v-if="!value.previous_category"
                                    :content="'Categoría desconocida'"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="mdi mdi-help text-lg"
                                ></span>
                                <span v-else :class="'icon-' + value.previous_category" class="text-lg"></span>
                            </div>
                            <div
                                :content="value.current_category"
                                v-tippy="{ followCursor: true, arrow: false }"
                                class="f-center m-1 w-8 h-8 rounded-full text-module"
                                :class="productCategory(value.current_category, value.alert)"
                            >
                                <span
                                    v-if="!value.current_category"
                                    :content="'Categoría desconocida'"
                                    v-tippy="{ followCursor: true, arrow: false }"
                                    class="mdi mdi-help text-lg text-gray1"
                                ></span>
                                <span v-else :class="'icon-' + value.current_category" class="text-lg"></span>
                            </div>
                        </div>

                        <div class="bg-white rounded-lg w-16 h-16 overflow-hidden mx-1">
                            <img
                                :class="{
                                    'w-full h-full object-cover': value.image,
                                    'w-16 rounded-lg h-full object-cover': !value.image,
                                }"
                                :src="value.image ? value.image : 'img/default.jpg'"
                                alt=""
                                @error="setAltImg($event)"
                            />
                        </div>

                        <div class="flex flex-col justify-start text-left mx-2">
                            <span class="md-title text-white">{{ value.sku }}</span>
                            <div class="whitespace-normal w-32 sm-title">
                                {{ value.name }}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:sales_general="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.sales_value | numFormat('0,0a') }} €</div>
                        <div class="sm-title">{{ item.sale_percent | numFormat('0.00') }}%</div>
                    </div>
                </template>

                <template v-slot:ctr="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">--</div>
                    </div>
                </template>

                <template v-slot:visits="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.visits | numFormat('0,0a') }}</div>
                        <div class="sm-title">{{ item.conversion | numFormat('0,00a') }}%</div>
                    </div>
                </template>

                <!-- <template v-slot:replacement="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.replacement | numFormat('0,0a') }} d</div>
                    </div>
                </template>

                <template v-slot:age="{ item }">
                    <div class="flex flex-col justify-center mx-2">
                        <div class="md-title text-white">{{ item.age | numFormat('0,0a') }} d</div>
                    </div>
                </template> -->

                <template v-slot:stock="{ item }">
                    <div class="flex items-center ml-6">
                        <i v-if="item.stock > 0" class="mdi mdi-check-circle mr-2 text-catBlue"></i>
                        <i v-else class="mdi mdi-close-circle mr-2 text-bad"></i>
                        <div
                            :class="{ 'text-catBlue': item.stock > 0, 'text-gray2': item.stock <= 0 }"
                            class="md-title text-white"
                        >
                            {{ item.stock | numFormat('0,0a') }}
                        </div>
                    </div>
                </template>
            </AdvancedTable>
        </template>
    </div>
</template>

<script>
    import Dropdown from 'bp-vuejs-dropdown';
    import ModuleHeader from '@/components/header/ModuleHeader.vue';
    import Table from '@/components/elements/Table.vue';
    import { actions, state } from '@/store';
    import AdvancedTable from '@/components/elements/AdvancedTable';
    import EventBus from '@/utils/event-bus';
    import Loading from '@/components/elements/Loading';
    import { setAltImg } from '@/utils/helpers';

    export default {
        name: 'ProductTablePro',
        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            data: {
                type: [Array, Object],
                required: true,
                default: () => ({}),
            },
            total: {
                type: Number,
                required: true,
                default: 0,
            },
        },
        components: {
            Loading,
            AdvancedTable,
            Dropdown,
            ModuleHeader,
            Table,
        },
        data() {
            return {
                search: null,
                selectedCatFrom: {
                    id: null,
                    name: null,
                },
                selectedCatTo: {
                    id: null,
                    name: null,
                },
                productCategories: [
                    {
                        id: null,
                        name: null,
                    },
                    {
                        id: 0,
                        name: 'hipertop',
                    },
                    {
                        id: 1,
                        name: 'top',
                    },
                    {
                        id: 2,
                        name: 'mejora',
                    },
                    {
                        id: 3,
                        name: 'market',
                    },
                ],
                fields: [
                    {
                        label: 'Producto',
                        key: 'product_item',
                        width: 200,
                    },
                    {
                        label: 'Ventas/ Peso',
                        key: 'sales_general',
                        width: 150,
                        format: 'fixed',
                        sortable: 'sales_value',
                    },
                    // {
                    //     label: '% Incremento',
                    //     key: 'sale_percent',
                    //     width: 100,
                    //     postfix: '%',
                    //     format: 'fixed',
                    // },
                    {
                        label: 'Beneficio',
                        key: 'profit',
                        width: 120,
                        postfix: ' €',
                        format: 'fixed',
                        sortable: 'profit',
                    },
                    {
                        label: '% Incremento',
                        key: 'profit_growth',
                        width: 100,
                        postfix: '%',
                        //sortable: 'profit_growth',
                    },
                    {
                        label: 'Vendidos',
                        key: 'sales',
                        width: 100,
                        sortable: 'sales',
                    },
                    {
                        label: 'Prev. 30 días (€)',
                        key: 'currency_prevision',
                        width: 120,
                        postfix: ' €',
                        format: 'fixed',
                        sortable: 'currency_prevision',
                    },
                    {
                        label: 'Prev. 30 días',
                        key: 'prevision',
                        width: 100,
                        //sortable: 'prevision',
                    },
                    {
                        label: 'Prev. 90 días (€)',
                        key: 'currency_prevision_90',
                        width: 120,
                        postfix: ' €',
                        format: 'fixed',
                        sortable: 'currency_prevision_90',
                    },
                    {
                        label: 'Prev. 90 días',
                        key: 'prevision_90',
                        width: 100,
                        //sortable: 'prevision',
                    },
                    {
                        label: 'Stock',
                        key: 'stock',
                        width: 120,
                        //sortable: 'stock',
                    },
                    {
                        label: 'Reposición',
                        key: 'replacement',
                        width: 100,
                        //sortable: 'replacement',
                    },
                    {
                        label: 'Visitas / %conv.',
                        key: 'visits',
                        width: 120,
                        postfix: '%',
                        //sortable: 'visits',
                    },
                    {
                        label: 'Edad',
                        key: 'age',
                        postfix: ' d',
                        width: 100,
                        //sortable: 'age',
                    },
                    {
                        label: 'Ventas 90d',
                        key: 'sales_90',
                        width: 100
                    },
                    {
                        label: 'Stock 90d',
                        key: 'stock_90',
                        width: 100
                    },
                    
                ],
                delayTimer: 0,
                awaitTime: 600,
            };
        },
        computed: {
            filteredData() {
                //if (this.data.length){
                //    return this.data.filter( item => item.stock > 0)
                //}
                //return []
                return this.data;
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
        },
        methods: {
            setAltImg,
            hasImage(image) {
                return image;
            },
            clearSearch() {
                this.search = null;
                EventBus.$emit('searchProducts', null);
            },
            searchProducts(event) {
                event.preventDefault();
                const value = event.target.value;

                if (value.length < 3) {
                    return;
                }

                clearTimeout(this.delayTimer);
                this.delayTimer = setTimeout(() => {
                    EventBus.$emit('searchProducts', value);
                }, this.awaitTime);
            },
            filterCatFrom(data) {
                this.selectedCatFrom.id = data.id;
                this.selectedCatFrom.name = data.name;
                actions.setCategoryFrom(this.selectedCatFrom.name);
            },
            filterCatTo(data) {
                this.selectedCatTo.id = data.id;
                this.selectedCatTo.name = data.name;
                actions.setCategoryTo(this.selectedCatTo.name);
            },
            transformSearch(string) {
                return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            },

            productCategory(category, alert) {
                return {
                    'border-2 border-yellow-400': alert,
                    'bg-catBlue bg-opacity-25 text-catBlue': category === 'hipertop',
                    'bg-catPurple bg-opacity-25 text-catPurple': category === 'top',
                    'bg-catPink bg-opacity-25 text-catPink': category === 'mejora',
                    'bg-catOrange bg-opacity-25 text-catOrange': category === 'market',
                };
            },
        },
        watch: {
            'selectedCatFrom.id'(n, o) {
                EventBus.$emit('fromChanged', this.selectedCatFrom);
            },
            'selectedCatTo.id'(n, o) {
                EventBus.$emit('toChanged', this.selectedCatTo);
            },
        },
    };
</script>
