<template>
    <div class="button-tab">
        <div class="tabs flex">
            <div
                v-ripple
                v-for="item in createdTabs"
                :key="item.id"
                v-model="activeTab" :value="item.id" @click="changeTab(item.id)" :class="{'active' : activeTab == item.id}" class="tab">
                {{item.tab}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ButtonTabs',
        props: ['createdTabs'],
        data() {
            return {
                activeTab: 0,
            }
        },
        methods: {
            changeTab(tab) {
                this.activeTab = tab;
                console.log('tab:', this.activeTab);
                this.$emit('selectedTab', tab)
            }
        },
    }
</script>