var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col"},[_c('ModuleHeader',{scopedSlots:_vm._u([{key:"module",fn:function(){return [_c('div',{staticClass:"w-full flex justify-between items-center"},[_vm._v("Categorías")])]},proxy:true},{key:"period",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("moment")(_vm.period.from,'DD MMM YY'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"}),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.period.to,'DD MMM YY'))+" ")]},proxy:true},{key:"market",fn:function(){return [_vm._v(" "+_vm._s(_vm.market.name)+" ")]},proxy:true}])}),[_c('div',{staticClass:"w-full flex flex-col sm:flex-row items-center sm:justify-between mb-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center mb-2 sm:mb-0",class:{
                        hidden: _vm.selectedType === 1,
                    }},[_c('i',{staticClass:"mdi mdi-magnify text-2xl text-gray2 mr-2"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"bg-input rounded-full border border-box px-4 py-2 text-gray1 w-72",attrs:{"placeholder":"Buscar categoría...","disabled":_vm.loadingSub,"readonly":_vm.loadingSub},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_c('ButtonTabs',{attrs:{"createdTabs":_vm.tabs},on:{"selectedTab":_vm.selectedTab}})],1)]),(_vm.selectedType === 1 && _vm.loadingSub)?_c('div',{staticClass:"flex flex-wrap items-center content-center justify-center w-full"},[_c('Loading')],1):_vm._e(),(_vm.selectedType === 1 && !_vm.loadingCat && !_vm.loadingSub)?_c('div',{staticClass:"flex w-full overflow-x-scroll overflow-y-hidden h-28"},[_c('div',{staticClass:"w-200 inline-flex relative"},_vm._l((_vm.filterCategories),function(subcategory,index){return _c('Box',{key:subcategory.id + '-' + index,class:{
                        active: _vm.filteredCategory === subcategory.id,
                    },attrs:{"classContainer":"box box-category w-52 inline-block mx-1"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"flex justify-between items-center py-0 cursor-pointer h-full",on:{"click":function($event){$event.preventDefault();return _vm.setCategory(subcategory.id)}}},[_c('div',{staticClass:"w-auto extra:w-5/12 mr-2"},[_c('img',{staticClass:"w-16 rounded-lg h-full object-cover mr-2",attrs:{"src":_vm.getImgSrc(subcategory.id),"alt":""},on:{"error":function($event){return _vm.setAltImg($event)}}})]),_c('div',{staticClass:"w-auto extra:w-7/12"},[_c('div',{staticClass:"md-title font-medium text-right extra:text-left px-1"},[_vm._v(" "+_vm._s(subcategory.name)+" ")]),(subcategory.references > 0)?_c('Chip',{attrs:{"colorBg":'bg-catBlue-opacity-33',"colorText":'text-catBlue'}},[_vm._v(" "+_vm._s(_vm._f("numFormat")(subcategory.references,'0,0'))+" referencias ")]):_c('Chip',{attrs:{"colorBg":'bg-regular-opacity-33',"colorText":'text-regular'}},[_vm._v(" Sin referencias ")])],1)])]},proxy:true}],null,true)})}),1)]):_vm._e(),_c('Table',{attrs:{"items":_vm.selectedType === 1 ? _vm.filterSubCategories : _vm.filterCategories,"fields":_vm.fields,"loading":_vm.selectedType === 1 ? _vm.loadingSub : _vm.loadingCat,"styleCont":_vm.getStyleCont},scopedSlots:_vm._u([{key:"image",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"flex"},[_c('img',{staticClass:"w-16 rounded-lg h-full object-cover",attrs:{"src":_vm.getImgSrc(item.image.slice(0, -9)),"alt":item.id},on:{"error":function($event){return _vm.setAltImg($event)}}})])]}},{key:"name",fn:function(ref){
                    var value = ref.value;
return [_c('div',{staticClass:"text-left pl-4"},[_vm._v(_vm._s(value))])]}},{key:"references",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v(_vm._s(_vm._f("numFormat")(item.references,'0,0')))]),_c('div',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm._f("numFormat")(item.references_percent,'0,0.0'))+" %")])])]}},{key:"visits",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v(_vm._s(_vm._f("numFormat")(item.visits,'0,0')))]),_c('div',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm._f("numFormat")(item.visits_percent,'0.0'))+" %")])])]}},{key:"sales_value",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v(_vm._s(_vm._f("numFormat")(item.sales_value,'0,0.0a'))+" €")]),_c('div',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm._f("numFormat")(item.sales_value_percent,'0.0'))+" %")])])]}},{key:"articles",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"flex flex-col justify-center mx-2"},[_c('div',{staticClass:"md-title text-white"},[_vm._v(_vm._s(_vm._f("numFormat")(item.articles,'0,0')))]),(_vm.selectedType === 0)?_c('div',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm._f("numFormat")(item.articles_percent,'0.0'))+" %")]):_c('div',{staticClass:"sm-title lowercase"},[_vm._v(_vm._s(_vm._f("numFormat")(item.articles_percent,'0.0'))+" %/"+_vm._s(item.category_name))])])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }