<template>
    <div class="w-full h-auto px-3 py-3">
        <Header />

        <template>
            <div class="grid grid-cols-12 grid-flow-row extra:grid-flow-col gap-3 w-full h-full flex-1">
                <div
                    class="
                        module
                        row-span-auto
                        sm:row-span-1
                        extra:row-span-1
                        col-span-12
                        sm:col-span-7
                        extra:col-span-3
                    "
                >
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="computedWareHouseQuery"
                        :data.sync="warehouse"
                        v-slot="{ loading: loadingWarehouse }"
                        :config="{ url: 'storehouse' }"
                    >
                        <Loading v-if="loadingWarehouse"></Loading>
                        <ProductWarehouse v-else :data="computedWareHouse" />
                    </All>
                </div>
                <div class="module col-span-1 col-span-12 sm:col-span-5 extra:col-span-3">
                    <ModuleTab />
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="computedProductsTopsQuery"
                        :data.sync="productsTop"
                        v-slot="{ loading: loadingTops }"
                        :config="{ url: 'tops' }"
                    >
                        <Loading v-if="loadingTops"></Loading>
                        <ProductTops v-else :moreSales="computedMoreSales" :lessSales="computedLessSales" />
                    </All>
                </div>
                <div class="module row-span-auto sm:row-span-2 col-span-12 extra:col-span-9">
                    <ModuleTab />
                   

                    <div
                        class="
                            relative
                            md:absolute
                            top-0
                            right-0
                            w-full
                            extra:w-4/12
                            flex
                            justify-center
                            sm:justify-end
                            items-center
                            z-50
                            mt-2
                            mr-4
                        "
                    >

                        <Download
                            ref="productsExcel"
                            action="Request"
                            model="ApiRequest"
                            :immediate="false"
                            :sensitive="false"
                            :query="computedProductsQuery"
                            :config="{ url: 'products-excel' }"
                            v-slot="{ data}"
                        >   
                            <div v-if="loadingExcel" class="w-full h-full relative opacity-50 flex justify-center items-center">
                                <img src="img/loading.svg" class="w-16" alt="" /><span class="text-gray1 text-xs">El archivo se descargará automáticamente</span>
                            </div>
                            <ButtonSecondary v-else @buttonAction="downloadExcel"> 
                                <i class="mdi mdi-download text-base mr-1"></i>
                                Descargar excel
                            </ButtonSecondary>
                        </Download>

                        <ButtonTabs :createdTabs="tabs" @selectedTab="selectedTab" />
                    </div>

                    <All
                        ref="ProductsQuery"
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="false"
                        :query="computedProductsQuery"
                        :data.sync="products"
                        v-slot="{ loading: loadingProducts }"
                        :config="{ url: 'products' }"
                        @update:data="gotProducts"
                    >
                        <div class="relative flex flex-wrap">
                            <ProductTablePro
                                v-show="table === 0"
                                :data="products.data.products"
                                :total="products.data.total"
                                :loading="loadingProducts"
                                class="relative"
                            />
                            <div class="relative block w-full">
                                <div
                                    class="
                                        md:flex md:flex-wrap md:justify-center md:mx-auto md:w-full md:mt-0 md:mb-0
                                        justify-items-center
                                        items-center
                                        text-center
                                    "
                                    v-if="
                                        !loadingProducts &&
                                        table === 0 &&
                                        paginate.totalPages > 0 &&
                                        paginate.totalPages > 1
                                    "
                                >
                                    <button
                                        class="md:inline md:w-auto md:mx-0 bg-transparent text-white font-medium mx-1"
                                        :class="{
                                            'opacity-50 pointer-events-none': paginate.page <= 1,
                                        }"
                                        @click="pageDown"
                                    >
                                        <i class="mdi mdi-chevron-left mr-2 text-lg"></i>
                                    </button>

                                    <span
                                        class="
                                            md:flex
                                            text-gray1
                                            md:inline md:w-auto md:mx-0
                                            mx-1
                                            text-center
                                            justify-items-center
                                        "
                                    >
                                        <input
                                            :value="paginate.page"
                                            class="
                                                bg-transparent
                                                text-right
                                                pr-1
                                                w-8
                                                focus focus:border-gray-300 focus:border-2
                                            "
                                            type="number"
                                            min="1"
                                            :max="paginate.totalPages"
                                            @input.prevent="jumpToPage"
                                        />
                                        /
                                        <span class="pl-1">
                                            {{ paginate.totalPages | numFormat('0,0') }}
                                        </span>
                                    </span>

                                    <button
                                        class="
                                            bg-transparent
                                            mx-1
                                            text-white
                                            font-medium
                                            md:inline md:w-auto md:mx-0 md:pl-1
                                        "
                                        :class="{
                                            'opacity-50 pointer-events-none': paginate.page === paginate.totalPages,
                                        }"
                                        @click="pageUp"
                                    >
                                        <i class="mdi mdi-chevron-right mr-2 text-lg"></i>
                                    </button>
                                </div>
                                <div
                                    v-if="!loadingProducts && table === 0 && products.data.total > 0"
                                    class="
                                        md:flex
                                        md:flex-wrap
                                        md:justify-center
                                        md:mr-0
                                        md:mt-1
                                        md:right-0
                                        md:absolute
                                        md:text-right
                                        md:top-0
                                        text-center
                                        mt-0
                                    "
                                >
                                    <span class="text-sm md:inline md:w-auto w-full block text-gray1">
                                        Mostrando: {{ fromReg | numFormat('0,0') }} al {{ toReg | numFormat('0,0') }} de
                                        {{ products.data.total | numFormat('0,0') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </All>
                    <All
                        action="Request"
                        model="ApiRequest"
                        :immediate="true"
                        :sensitive="true"
                        :query="computedCategoriesQuery"
                        :data.sync="categories"
                        v-slot="{ loading: loadingCategories }"
                        :config="{ url: 'categories' }"
                    >
                        <All
                            action="Request"
                            model="ApiRequest"
                            :immediate="true"
                            :sensitive="true"
                            :query="computedSubCategoriesQuery"
                            :data.sync="subcategories"
                            v-slot="{ loading: loadingSubcategories }"
                            :config="{ url: 'subcategories' }"
                        >
                            <ProductTableCat
                                v-show="table === 1"
                                :categories="categories.data"
                                :subcategories="subcategories.data"
                                :loadingSub="loadingSubcategories"
                                :loadingCat="loadingCategories"
                            />
                        </All>
                    </All>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { All, Download } from '@/api/components';
    import { actions, state } from '@/store';
    import Header from '@/components/header/Header.vue';
    import Loading from '@/components/elements/Loading.vue';
    import ModuleTab from '@/components/elements/ModuleTab';
    import ProductWarehouse from '@/components/module/ProductWarehouse.vue';
    import ProductTops from '@/components/module/ProductTops.vue';
    import ProductTablePro from '@/components/module/ProductTablePro.vue';
    import ProductTableCat from '@/components/module/ProductTableCat.vue';

    import ButtonSecondary from '@/components/elements/ButtonSecondary.vue';
    import ButtonTabs from '@/components/elements/ButtonTabs.vue';
    import Paginate from '@/api/components/Paginate';

    import EventBus from '@/utils/event-bus';

    export default {
        name: 'ProductsPage',
        components: {
            Paginate,
            Header,
            Loading,
            ModuleTab,
            ProductWarehouse,
            ProductTops,
            ProductTablePro,
            ProductTableCat,
            ButtonSecondary,
            ButtonTabs,
            All,
            Download,
        },
        data() {
            return {
                table: 0,
                tabs: [
                    {
                        id: 0,
                        tab: 'Productos',
                    },
                    {
                        id: 1,
                        tab: 'Categorías',
                    },
                ],
                categories: [],
                subcategories: [],
                products: {
                    data: {},
                    total: 0,
                },
                warehouse: {},
                productsTop: {},
                search: null,
                limitProducts: 60,
                paginate: {
                    limit: 20,
                    page: 1,
                    total: 0,
                    totalPages: 0,
                    order_field: null,
                    order_direction: null,
                },
                orderBy: 'currency_prevision',
                orderDir: 'desc',
                delayTimer: 0,
                awaitTime: 800,
                loadingExcel: false
            };
        },
        computed: {
            getProductsLength() {
                return this.products.data.products.length;
            },
            fromReg() {
                if (this.paginate.total === 0) {
                    return this.paginate.total;
                }
                if (this.paginate.total > 0 && this.paginate.total <= this.limitProducts) {
                    return 1;
                }
                return (this.paginate.page - 1) * this.limitProducts + 1;
            },
            toReg() {
                if (this.paginate.total === 0) {
                    return 0;
                }
                return this.fromReg + this.getProductsLength - 1;
            },
            computedProductsQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                    order_field: this.orderBy,
                    order_direction: this.orderDir,
                    limit: this.limitProducts,
                    offset: this.limitProducts * (this.paginate.page - 1),
                    from_category: this.from_category,
                    to_category: this.to_category,
                    term: this.search,
                };
            },

            computedWareHouseQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                };
            },
            computedProductsTopsQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                    order_field: 'sales_value',
                    order_direction: 'desc',
                    limit: this.limitProducts,
                    offset: 0,
                };
            },
            computedCategoriesQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                    order_field: 'sales_value',
                    order_direction: 'desc',
                    limit: 0,
                    offset: 0,
                };
            },
            computedSubCategoriesQuery() {
                return {
                    from: this.period.from,
                    to: this.period.to,
                    country: this.marketId,
                    order_field: 'name',
                    order_direction: 'desc',
                    limit: 0,
                    offset: 0,
                };
            },
            computedWareHouse() {
                return this.warehouse.data ? this.warehouse.data : {};
            },
            computedMoreSales() {
                return this.productsTop.data && this.productsTop.data.moreSales ? this.productsTop.data.moreSales : [];
            },
            computedLessSales() {
                return this.productsTop.data && this.productsTop.data.lessSales ? this.productsTop.data.lessSales : [];
            },
            user() {
                return state.user;
            },
            period() {
                return {
                    from: this.$moment(state.period.from).format('YYYY-MM-DD'),
                    to: this.$moment(state.period.to).format('YYYY-MM-DD'),
                };
            },
            market() {
                return state.market;
            },
            marketId() {
                return state.market.id;
            },
            from_category() {
                return state.from_category;
            },
            to_category() {
                return state.to_category;
            },
        },
        methods: {
            downloadExcel() {
                this.loadingExcel = true;
                this.$refs.productsExcel.download();
                setTimeout(() => {
                   this.loadingExcel = false;
                }, 10000)
            },
            
            jumpToPage($event) {
                const page = $event.target.value;
                if (page > this.paginate.totalPages || page < 1) {
                    $event.target.value = this.paginate.page;
                    return;
                }

                clearTimeout(this.delayTimer);
                this.delayTimer = setTimeout(() => {
                    this.paginate.page = parseInt(page);
                    this.doProductQuery();
                }, this.awaitTime);
            },
            doProductQuery() {
                this.$refs.ProductsQuery.request();
            },
            gotProducts() {
                if (this.products.data.products.length) {
                    this.paginate.total = this.products.data.total ? this.products.data.total : 0;
                    this.paginate.totalPages = this.products.data.total
                        ? Math.ceil(this.products.data.total / this.limitProducts)
                        : 0;
                } else {
                    this.resetPaginate();
                }
            },
            pageUp() {
                if (this.paginate.page === this.paginate.totalPages) {
                    return;
                }

                this.paginate.page++;
                this.doProductQuery();
            },
            pageDown() {
                if (this.paginate.page === 1) {
                    return;
                }

                this.paginate.page--;
                this.doProductQuery();
            },
            selectedTab(tab) {
                this.table = tab;
            },
            resetPaginate() {
                this.paginate.total = 0;
                this.paginate.page = 1;
                this.paginate.totalPages = 1;
            },
            resetAndQuery() {
                this.resetPaginate();
                this.doProductQuery();
            },
        },
        mounted() {
            // adding eventBus listener
            EventBus.$on('filtered', () => {
                this.resetAndQuery();
            });

            EventBus.$on('fromChanged', res => {
                this.resetPaginate();
                actions.setCategoryFrom(res.name);
                this.doProductQuery();
            });
            EventBus.$on('toChanged', res => {
                this.resetPaginate();
                actions.setCategoryTo(res.name);
                this.doProductQuery();
            });

            EventBus.$on('searchProducts', res => {
                this.search = res;
                this.resetAndQuery();
            });
            EventBus.$on('sorted', res => {
                this.orderBy = res.sortBy;
                this.orderDir = res.sortDir;
                this.resetAndQuery();
            });
        },
        beforeCreate() {
            actions.setPeriod({
                from: this.$moment().startOf('month').locale('es').toDate(),
                to: this.$moment().endOf('month').subtract(1, "days").toDate(),
            });
        },
        beforeDestroy() {
            // removing eventBus listener
            EventBus.$off(['filtered', 'sorted', 'searchProducts', 'fromChanged', 'toChange']);
        },
    };
</script>
<style>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
